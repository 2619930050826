function init() {
    var slider_hero_find = $(".carousel__section");
    if (slider_hero_find) {
        slider_hero_find.find(".carousel-indicators > li:first-child").addClass("active");
        slider_hero_find.find(".carousel-inner > .carousel-item:first-child").addClass("active");
        var slider_gellary_find = $(".gallery__section");
        slider_gellary_find.find(".carousel-inner > .carousel-item:first-child").addClass("active");
    }

}

$(document).ready(function () {
    init();
})