function init() {
    if ($("body").hasClass("renasantinsurance")) {
        $(".icon-cta .list-group-item").each(function () {
            var target = $(this).data("icon-target");
            if (target !== undefined) {
                target = target.toLowerCase();
                var href = window.location.href.toLowerCase();
                var urlLastValue = href.substring(window.location.href.lastIndexOf('/') + 1);
                if (target === urlLastValue) {
                    $(this).addClass("active");
                }
            }
        });
        $(".icon-cta").show();
    }
}

$(document).ready(function () {
    init();
})