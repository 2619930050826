function init() {
    if ($('.alert_banner').length > 0) {
        var headerHeight = $('header').height();
        var bannerHeight = $('.alert_banner').height();
        var alertBannerVisited = getCookie("alertbanner");
        if (alertBannerVisited === "" || alertBannerVisited === "false") {
            $('.alert_banner').show();
            $('.spacer').height(headerHeight + bannerHeight);
        }


        $('.alert_banner__close').click(function () {
            setCookie("alertbanner", true, 1);
            $('.alert_banner').hide();
            $('.spacer').height(headerHeight);
        });
    }

    $(window).resize(function () {
        var headerHeight = $('header').height();
        if ($('.alert_banner').length > 0) {
            var bannerHeight = $('.alert_banner').height();
            $('.spacer').height(headerHeight + bannerHeight);
        } else {
            $('.spacer').height(headerHeight);
        }
    });
}


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(document).ready(function () {
    init();
})