function init() {
    $('.showpopup-herothreecolumns').on("click", function (event) {
        if ($('.activate-decision-modal').length > 0)
            $('.activate-decision-modal').show();
    });

    $('body').on('mouseenter', '.home-hero .col3.not-opened', function () {
        var hovered = $(this);
        var intro_src;
        hoverTime = setTimeout(function () {
            hovered.find('.option-content .option-icon img.intro-img').removeClass('reveal');
            hovered.find('.option-content .option-icon img.loop-img').addClass('reveal');
        }, 1000);

        clearTimeout(hoverTime);

        hovered.find('.option-content .option-icon img.icon-img').removeClass('reveal');

        intro_src = hovered.find('.option-content .option-icon img.intro-img').attr('src');
        hovered.find('.option-content .option-icon img.intro-img').addClass('reveal');

        hoverTime = setTimeout(function () {
            hovered.find('.option-content .option-icon img.intro-img').removeClass('reveal');
            hovered.find('.option-content .option-icon img.loop-img').addClass('reveal');
        }, 1000);
    });

    $('body').on('mouseleave', '.home-hero .col3.not-opened', function () {
        var hovered = $(this);

        clearTimeout(hoverTime);

        hovered.find('.option-content .option-icon img.icon-img').addClass('reveal');
        hovered.find('.option-content .option-icon img.intro-img').removeClass('reveal');
        hovered.find('.option-content .option-icon img.loop-img').removeClass('reveal');

        var intro_src = hovered.find('.option-content .option-icon img.intro-img').attr('src');
        var loop_src = hovered.find('.option-content .option-icon img.loop-img').attr('src');

        hovered.find('.option-content .option-icon img.intro-img').attr('src', '');
        hovered.find('.option-content .option-icon img.loop-img').attr('src', '');

        hovered.find('.option-content .option-icon img.intro-img').attr('src', intro_src);
        hovered.find('.option-content .option-icon img.loop-img').attr('src', loop_src);
    });
    
}

$(document).ready(function () {
    var hoverTime;
    init();

})