function init() {

    if ($('#loan-type-check-modal').length > 0) {
        $('.close-type-modal').click(function () {
            $('#loan-type-check-modal').hide();
            $('.loan-type-question .init-question').show();
            $('.loan-type-question .please-call').hide();
        });

        $('.mortagage-yes-popup-button-clicked').click(function () {
            $('.loan-type-question .init-question').hide();
            $('.loan-type-question .please-call').show();
        });
    }

    $(document).on("click",
        ".makepayment",
        function (e) {
            e.preventDefault();
            $('#loan-type-check-modal').show();
        });
}

$(document).ready(function () {
    init();
})