function openBioPopup(e, counter) {
    try {
        var myDiVElem = document.getElementById("bioInner");
        myDiVElem.innerHTML = "";
        myDiVElem.innerHTML = document.getElementById("bio-" + counter).innerHTML;
        $('#BioPopup').modal('show');
        $('#BioPopup').appendTo("body");
        e.preventDefault();
    } catch (e) {
        //alert(e.message);
    }
}
$('a.ReadBio[data-counterAdvisor]').on('click', function (e) {
    openBioPopup(e, $(this).attr('data-counterAdvisor'));
    e.preventDefault();
})

$(".find-local-private-banker .expand, .find-local-private-banker .collapse").on("click", function () {
    $(".find-local-private-banker .expand").toggle();
    $(".find-local-private-banker .collapse").toggle();
    $(".column_advisor .container-content > .btn").click();
});
