/*jshint esversion: 6 */
// Widgets Components -------------------------------------- *
require('../Views/Feature/Widgets/RichText/richText.js');
require('../Views/Feature/Widgets/GalleryCarousel/galleryCarousel.js');
require('../Views/Feature/Widgets/InPageTab/inPageTab.js');
require('../Views/Feature/Widgets/OneTileCta/oneTileCta.js');
require('../Views/Feature/Widgets/Cta/cta.js');
require('../Views/Feature/Widgets/MultiLineCards/multiLineCards.js');
require('../Views/Feature/Widgets/TwoColumnContentWithImageOverlay/twoColumnContentWithImageOverlay.js');
require('../Views/Feature/Widgets/AlternativeCallout/alternativeCallout.js');
require('../Views/Feature/Widgets/InPageVerticalTab/inPageVerticalTab.js');
require('../Views/Feature/Widgets/SpiritCard/spiritCard.js');
require('../Views/Feature/Widgets/HistorySlider/historySlider.js');
require('../Views/Feature/Widgets/Stripe/stripe.js');
require('../Views/Feature/Widgets/HeroImageOnly/heroImageOnly.js');
require('../Views/Feature/Widgets/ThreeColumnSteps/threeColumnSteps.js');
require('../Views/Feature/Widgets/RightRailCta/rightRailCta.js');
require('../Views/Feature/Widgets/TwoColumnIconList/twoColumnIconList.js');
require('../Views/Feature/Widgets/FeatureComparisonGrid/featureComparisonGrid.js');
require('../Views/Feature/Widgets/HeroBannerCarousel/heroBannerCarousel.js');
require('../Views/Feature/Widgets/HeroBanner/heroBanner.js');
require('../Views/Feature/Widgets/HeroWithRte/heroWithRte.js');
require('../Views/Feature/Widgets/IconCta/iconCta.js');
require('../Views/Feature/Widgets/SimpleText/simpleText.js');
require('../Views/Feature/Widgets/ContactForm/contactForm.js');
require('../Views/Feature/Widgets/Advisor/advisor.js');
require('../Views/Feature/Widgets/InfoCard/infoCard.js');
require('../Views/Feature/Widgets/StockTicker/stockTicker.js');
require('../Views/Feature/Widgets/NewsLetter/newsLetter.js');
require('../Views/Feature/Widgets/AlertBanner/alertBanner.js');
require('../Views/Feature/Widgets/LoanOfficerHeroWithSearch/loanofficerherowithsearch.js');
require('../Views/Feature/Widgets/MortgageHeroBanner/mortgageHeroBanner.js');
require('../Views/Feature/Widgets/MortgagePopupDialog/mortgagePopupDialog.js');
require('../Views/Feature/Widgets/MortgageThreeColumns/mortgageThreeColumns.js');
require('../Views/Feature/Widgets/MortgageHeroThreeColumns/mortgageHeroThreeColumns.js');
require('../Views/Feature/Widgets/MortgageFAQ/mortgageFAQ.js')
require('../Views/Feature/Widgets/DecisionSlider/decisionSlider.js');