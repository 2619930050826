function init() {
    var equalHeight = function (element) {
        var currentTallest = 0, //Tallest Blog Item
            currentRowStart = 0, //Current Blog item Row
            rowBlogItems = new Array(), //Array of Blog Items
            $this,
            _itemtTopPosition = 0; //Top postion of the blog item

        //goes through each blog item
        $(element).each(function () {
            $this = $(this);
            //grabs the top postions of each blog item
            _itemtTopPosition = $this.position().top;
            if (currentRowStart != _itemtTopPosition) {

                // we just came to a new row. �Set all the heights on the completed row
                for (currentBlogItem = 0; currentBlogItem < rowBlogItems.length; currentBlogItem++) {
                    rowBlogItems[currentBlogItem].height(currentTallest);
                }
                // set the variables for the new row
                rowBlogItems.length = 0; // empty the array
                currentRowStart = _itemtTopPosition;
                currentTallest = getInnerHeight($this);
                rowBlogItems.push($this);
            } else {
                rowBlogItems.push($this);
                currentTallest = (currentTallest < getInnerHeight($this)) ? (getInnerHeight($this)) : (currentTallest);
            }

            // do the last row

            for (currentBlogItem = 0; currentBlogItem < rowBlogItems.length; currentBlogItem++) {
                if (window.outerWidth > 767) {
                    rowBlogItems[currentBlogItem].height(currentTallest);
                } else {
                    rowBlogItems[currentBlogItem].height('auto');
                }
            }
        });

    }

    function getInnerHeight(obj) {
        var totalHeight = 0;
        $(obj).children().each(function () {
            if ($(this).css('display') != 'none') {
                totalHeight = totalHeight + $(this).outerHeight(true);
            }
        });
        return totalHeight;
    }
    var gallery_carousel = $('.gallery_sectionsecond .container-1800 .owl-carousel');
    gallery_carousel.owlCarousel({
        center: true,
        loop: true,
        lazyLoad: true,
        lazyLoadEager: 1,
        items: 4,
        dots: false,
        autoHeight: false,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        onInitialized: function () {
            $('.gallery_sectionsecond .container-1800 .owl-carousel').find('.owl-prev').attr('aria-label', 'navigate left').removeAttr('role');
            $('.gallery_sectionsecond .container-1800 .owl-carousel').find('.owl-next').attr('aria-label', 'navigate right').removeAttr('role');
        },
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 3
            },
            1200: {
                items: 4
            },
            2000: {
                items: 5
            }
        }
    });

    equalHeight(".gallery_sectionsecond .gallery_tile_upper ");

    $(window).resize(function () {
        equalHeight(".gallery_sectionsecond .gallery_tile_upper ");
    });
}

$(document).ready(function () {
    init();
});

