function init() {
    $('.showpopup-threecolumn').on("click", function (event) {
        var $this = $(this);
        if ($this.attr("data-threecolumnshowpopup") == "True") {
            var elementclasslist = $this.attr('class').split(' ');
            event.preventDefault();
            if (!elementclasslist.includes("faq-anchor")) {
                $('#loan-type-check-modal').show();
            }
            else {
                if ($('#servicing-faq').length > 0) {
                    var _top = $('#servicing-faq').offset().top - $("header").height();
                    $("html, body").animate({ scrollTop: _top }, 500);
                }
            }
        }
    });
}

$(document).ready(function () {
    init();
})