/*jshint unused: false, undef:false, latedef: false */

$(document).ready(function(){

	var position = $(window).scrollTop();

	// NAV ICON
	$('#nav-icon').click(function(){
		if(!$('#mobile-menu').hasClass('active')){
			$('#mobile-menu').addClass('active').outerWidth();
			$('#mobile-menu').addClass('opaque');
			$('html, body').addClass('fixed');
		}
	});

	// MOBILE MENU
	$('#close-mobile-menu').click(function(){
		$('#mobile-menu').removeClass('opaque');
		$('html, body').removeClass('fixed');

		setTimeout(function(){
			$('#mobile-menu').removeClass('active').outerWidth();
		}, 350);
	});

	$('ul.category-tabs li').click(function(){
		var clicked = $(this);

		if(!clicked.hasClass('active')){
			$('ul.category-tabs li').removeClass('active');
			clicked.addClass('active');

			$('.list-container').removeClass('active');
			$('.list-container').eq(clicked.index()).addClass('active');
		}
	});



});
