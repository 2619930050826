function init() {

    $('.single-faq').click(function () {
        var clicked = $(this);


        if (!clicked.hasClass('active')) {
            if ($('.single-faq.active').length > 0) {
                $('.single-faq.active .single-answer').slideUp(150, function () {
                    $('.single-faq.active').removeClass('active');
                    clicked.addClass('active');
                    $('.single-faq.active .single-answer').slideDown(150, function () {
                        $('html, body').animate({ 'scrollTop': $('.single-faq.active').offset().top - $('header').height() - 20 });
                    });
                });
            }


            else {
                clicked.addClass('active');
                clicked.find('.single-question').addClass('active');
                $('.single-faq.active .single-answer').stop().slideDown(150, function () {
                    $('html, body').animate({ 'scrollTop': $('.single-faq.active').offset().top - $('header').height() - 20 });
                });
            }
        }


        else {
            $('.single-faq.active .single-answer').stop().slideUp(150);
            clicked.removeClass('active');
            clicked.find('.single-question').removeClass('active');
        }
    });

    $('.servicing-home .no-faq').click(function () {
        $('.servicing-home #faq-wrapper').addClass('open').show();
        $('.servicing-home .faq-type').hide();
        $('.servicing-home .init-question').hide();
    });

    $('.servicing-home .yes-faq').click(function () {
        $('.servicing-home .faq-type').addClass('open').show();
        $('.servicing-home .init-question').hide();
        $('.servicing-home .please-call').show();
    });

    $('.confirmation-popup-close-btn').click(function () {
        if ($('#faq-wrapper').hasClass('open')) {
            $('#faq-wrapper').hide();
            $('#faq-wrapper').removeClass('open');
        } else if ($('.faq-type').hasClass('open')) {
            $('.please-call').hide();
            $('.faq-type').removeClass('open');
        }

        $('.servicing-home .init-question').show();
    });
}

$(document).ready(function () {
    init();
})