function init() {
    var currentCard;
    var prevCard = [];
    $('.dc-tree .close-modal').off('click');
    $('.dc-tree .close-modal').on('click', function () {

    });

    $(document).on("click", ".dc-tree .close-modal", function () {
        $('.decision-slider').hide();
        $('.decision-slider-overlay').hide();
        $("body, html").css("overflow", "auto");
        $("body").css("position", "initial");
    });

    $('.dctree-prev').on('click', function () {
        showCard("slide-left", prevCard.pop(), true);
    });

    $(".dctree-btn").click(function () {
        getNextCard($(this));
    });

    function getNextCard(elem) {
        var e = $(elem);
        currentCard = e.parents(".dctree-card")[0];
        prevCard.push(currentCard.id);
        var nextCard = e.attr('data-dctree-targetid');
        showCard("slide-left", nextCard, false);
    }

    $(".activate-decision-modal").click(function () {
        $(".decision-slider").show();
        $(".decision-slider-overlay").show();
        $("body, html").css("overflow", "hidden");
        $("body").css("position", "relative");
    });

    function showCard(animation, id, backward) {
        var nextCard = $("#" + id);
        if (animation == 'slide-left') {
            var left = { left: "-100%" };
            var card = $(currentCard);

            if (backward) {
                left = { left: "100%" };
            }

            card.animate(left, 300, function () {
                card.hide();
            });

            if (nextCard.css('left') == "-100%" || nextCard.css('left') == "100%") {
                left.left = 0;
                nextCard.show().animate(left, 300);
            } else {
                nextCard.show();
            }
        }

        currentCard = nextCard;
    }

    $(".restart-tree").click(function () {
        $(".dctree-card").css({ "display": "none", "left": "100%" });
        $(".first").css({ "display": "block", "left": "0" });
    });
}

$(document).ready(function () {
    init();
});